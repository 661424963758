<template>
  <div class="home_top">
    <div class="inner inner1">
      <div class="left_item">
        <div class="wbg">
          <h1>CONSIGUE RÁPIDO & FÁCIL</h1>
          <h1>PRESTAMO USANDO CAUDALSUBE</h1>
        </div>
        <div class="item_info">
          <!-- <img class="info_img1" src="@/assets/images/loanImg/icon_info.png" alt=""> -->
          <div class="info_tips">
            <p><img class="info_img1" src="@/assets/images/loanImg/icon_item_tick.png" alt="">Aplicación completamente sin papel</p>
            <p><img class="info_img1" src="@/assets/images/loanImg/icon_item_tick.png" alt="">Completamente La seguridad & Seguro</p>
            <p><img class="info_img1" src="@/assets/images/loanImg/icon_item_tick.png" alt="">Bajas tasas de interés</p>
          </div>
          <!-- <img class="info_img2" src="@/assets/images/loanImg/icon_info2.png" alt=""> -->
        </div>
        <div class="item_download" @click="downloadApp()">
          <img src="@/assets/images/Google.png" alt="">
        </div>
      </div>
      <div class="right_item">
        <!-- <img src="@/assets/images/banner01.png" alt=""> -->
      </div>
    </div>
    <div class="inner inner2">
      <h3>Préstamo en 3 pasos</h3>
      <div class="inner2_item flex jc-sb">
        <div v-for="(item,index) in leftItemList" :key="index" class="content">
          <img class="bg" :src="item.img" alt="">
          <img class="bg2" :src="item.img2" alt="">
          <div class="item_info">
            <!-- <h4>{{ item.title }}</h4> -->
            <h1>{{ item.txt }}</h1>
            <p>{{ item.txt2 ? item.txt2 : `&nbsp;` }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="inner inner3">
      <img class="bg" src="@/assets/images/inner3_bg.png">
      <div class="title">
        <h3>Nuestra ventaja</h3>
      </div>
      <div class="flex fw-w jc-sb">
        <div v-for="(item,index) in inner_3items" :key="index" class="content flex">
          <img :src="item.img">
          <div>
            <p>{{ item.txt }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="inner inner4">
      <h3>Por qué elegir Mipréstamo</h3>
      <div class="flex fd-c">
        <div v-for="(item,index) in inner_4items" :key="index" class="content flex" :class="index === 1 ?'fd-r jc-sb':''">
          <img :src="item.img">
          <div class="content2 flex fd-c jc-sb">
            <h1>{{ item.title }}</h1>
            <div v-for="(item2,index2) in item.list2" :key="index2">
              <p v-if="item2.txt">{{ item2.txt }}</p>
              <h2 v-if="item2.txt2">{{ item2.txt2 }}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="selector1" class="inner inner5">
      <h3>Ellos nos eligieron</h3>
      <div class="flex al-c jc-c">
        <button class="carousel-button" :disabled="currentIndex === 0" @click="previousSlide"><img src="@/assets/images/loanImg/black_left.png"></button>
        <div class="carousel-container">
          <div class="carousel">
            <transition-group name="fade" tag="div" class="carousel-wrapper">
              <div v-for="(item) in visibleItems" :key="item.id" class="carousel-item flex fd-c jc-sb">
                <img class="tx" :src="item.img">
                <div class="flex stars">
                  <img v-for="index in 5" :key="index" src="@/assets/images/loanImg/star.png" >
                </div>
                <div>
                  <h1>{{ item.txt }}</h1>
                  <p>{{ item.txt2 }}</p>
                </div>
              </div>
            </transition-group>
          </div>
        </div>
        <button class="carousel-button" :disabled="currentIndex >= items.length - 3" @click="nextSlide"><img src="@/assets/images/loanImg/black_right.png"></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      leftItemList: [
        { txt: 'Información perfecta', txt2: 'Solo 5 minutos', img: require('@/assets/images/itemList_01.png'), img2: require('@/assets/images/itemList_04.png') },
        { txt: 'Aprobación rápida', txt2: 'Aprobación en 1 horas', img: require('@/assets/images/itemList_02.png'), img2: require('@/assets/images/itemList_05.png') },
        { txt: 'Préstamo', txt2: 'Llegó dentro de las 24 horas', img: require('@/assets/images/itemList_03.png'), img2: require('@/assets/images/itemList_06.png') }
      ],
      leftItemList2: [
        { title: 'What can you get?', list2: [{ img: require('@/assets/images/list01.png'), txt: 'From 1,000 ₱ up to 50,000 ₱' }, { img: require('@/assets/images/list02.png'), txt: 'Repayment Term：0-180 days' }, { img: require('@/assets/images/list03.png'), txt: 'Annual interest rate：10%-35%' }] },
        { title: 'Who is eligible to get a loan?', list2: [{ img: require('@/assets/images/list04.png'), txt: 'Age 18-60 years' }, { img: require('@/assets/images/list05.png'), txt: 'Philippines citizen' }, { img: require('@/assets/images/list06.png'), txt: 'Employed or Business-owner' }] }
      ],
      inner5ItemLeftList: [
        { title: 'Online service', txt: 'You can find online services in ', txt2: 'the CaudalSube app.', img: require('@/assets/images/ng_1.png') },
        { title: 'E-mail', txt: 'tigercreditapp@gmail.com', img: require('@/assets/images/ng_2.png') },
        { title: 'Customer service hours', txt: '9AM-6PM', img: require('@/assets/images/ng_3.png') }
      ],
      items: [
        // 您的轮播内容数据
        { id: '1', txt: 'Antonio Maldonado Evangelista', txt2: 'Me chifla mucho CaudalSube que es una app de \n préstamo persomal online súper rápido.Solo con 5 \n minutos ya conseguí 2.000 pesos.Me traio unagran \n ayuda a mi y a mi familia.', img: require('@/assets/images/Product01.png') },
        { id: '2', txt: 'Teri Dol Blance', txt2: 'Creo que en muy poco tiempo esta aplicación se \n ha convertido en un verdadero amigo que me \n necesita, ya que pude solucionar todos mis \n problemas financieros con la ayuda de planes \n de préstamos instantáneos.', img: require('@/assets/images/Product02.png') },
        { id: '3', txt: 'IGNACIO HERNANDEZ CRUZ', txt2: 'El proceso de la solicitud del préstamo es muy \n sencillo. El préstamo está con bajo interés del \n monto alto.Me brinda muchas', img: require('@/assets/images/Product03.png') },
        { id: '4', txt: 'Erika Kassandra Bravo', txt2: 'Cuando me urge dinero me recomendaror \n CaudalSube que es uan app super buena!', img: require('@/assets/images/Product04.png') },
        { id: '5', txt: 'ADALBERTO SEGURA RIVERA', txt2: 'Excelente recurso, me ayudó a quedarme atascado. \n Esta aplicación es genial, te ayuda a administrar tus \n objetivos financieros y a mantenerte al día.', img: require('@/assets/images/Product05.png') }
      ],
      visibleItemCount: 3,
      currentIndex: 0,
      inner_4items: [
        { img: require('@/assets/images/inner_4_1.png'), title: 'Operación simple y rápida', list2: [{ txt: 'Descargue, instale la aplicación y solicite un préstamo rápido' }, { txt: 'Aprobar archivos automáticamente' }, { txt: 'El sistema aprueba automáticamente el monto del préstamo.' }] },
        { img: require('@/assets/images/inner_4_2.png'), title: 'Interés bajo', list2: [{ txt: 'El interés del préstamo es muy competitivo y \n ofrece ofertas especiales' }, { txt: 'El procedimiento es sencillo, siempre que la \n cédula de identidad.' }] },
        { img: require('@/assets/images/inner_4_3.png'), title: 'Préstamo rápido', list2: [{ txt2: 'Una vez aprobado el sistema, el dinero se transferirá \n directamente a su tarjeta bancaria.' }] }
      ],
      inner_3items: [
        { img: require('@/assets/images/list07.png'), txt: 'Puede elegir la cantidad y \n el límite de tiempo' },
        { img: require('@/assets/images/list10.png'), txt: 'Puede solicitar un préstamo \n en cualquier momento' },
        { img: require('@/assets/images/list08.png'), txt: 'Preste dinero todo el día los \n 365 días, no se preocupe por los \n fines de semana y feriados' },
        { img: require('@/assets/images/list11.png'), txt: 'Solo necesitas enviar una solicitud, \n te atenderemos durante todo el \n proceso' }
      ],
      inner6ItemLeftList: [
        { title: 'apexlendinghelp@outlook.com', img: require('@/assets/images/email.png') },
        { title: '78 Ogunnusi Road, Ojodu Berger, Lagos State.', img: require('@/assets/images/address.png') },
        { title: '9AM-5PM', img: require('@/assets/images/time.png') }
      ]
    }
  },
  computed: {
    visibleItems() {
      return this.items.slice(this.currentIndex, this.currentIndex + this.visibleItemCount)
    }
  },
  created() {
    // setInterval(() => {
    //   this.nextSlide()
    // }, 4)
  },
  methods: {
    inner_2Class(index) {
      if (index === 4) {
        return 'content2'
      } else if (index % 2 !== 0) {
        return 'text_r'
      }
    },

    downloadApp() {
      window.open('https://play.google.com/store/apps/details?id=caudal.sube.credito', '_blank')
    },
    previousSlide() {
      if (this.currentIndex > 0) {
        this.currentIndex--
      }
    },
    nextSlide() {
      if (this.currentIndex < this.items.length - this.visibleItemCount) {
        this.currentIndex++
      } else {
        this.currentIndex--
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home_top{
  min-width: 1200px;
  background: #fff;
  white-space: pre-line;
  .inner{
    width: 100%;
    padding:60px calc((100% - 1100px) / 2);
    margin: 0 auto;
  }
  .inner1{
    // background: linear-gradient(258deg, #43C5D6 0%, #4484EC 100%);
    padding:60px calc((100% - 1100px) / 2);
    background: url("~@/assets/images/loanImg/inner1_bg.png") no-repeat;
    background-size: 100% 100%;
    height: 550px;
    display: flex;
    justify-content: space-between;
    .left_item{
      box-sizing: border-box;
      border-radius: 10px;
      h1{
        font-size: 40px;
        color: #FFFFFF;
        font-weight: 700;
        // margin-bottom: 10px;
        line-height: 70px;
        span{
          color: #00BB53;
        }
      }
      h4{
        font-size: 16px;
        line-height: 30px;
        color: #666666;
        font-weight: normal;
        // margin: 0px 0;
      }
      & h4:nth-of-type(1){
        margin-top: 40px;
      }
      .item_info{
        margin: 20px 0;
        display: flex;
        height: 170px;
        .info_img1{
          width: 18px;
        }
        .info_tips{
          display: flex;
          flex-direction: column;
          p{
            flex: 1;
            display: flex;
            box-sizing: border-box;
            // margin-left: 10px;
            font-size: 16px;
            color: #FFFFFF;
            align-items: center;
            img{
              margin-right: 10px;
            }
          }
        }
        .info_img2{
          width: 135px;
          height: 135px;
        }
      }
      .item_download{
        // padding-left: 22px;
        cursor: pointer;
        margin-top: 40px;
        color: white;
        border-radius: 5px;
        img{
          width: 190px;
          height: 60px;
          margin-right: 5px;
        }
      }
    }
    .right_item{
      img{
        height: 376px;
        margin-right: 30px;
      }
    }
  }
  .inner2{
    position: relative;
    padding:80px calc((100% - 1100px) / 2) 60px;
    // background: linear-gradient(86deg, #013799 0%, #0071D6 100%);
    h3{
      font-size: 40px;
      text-align: center;
      margin-bottom: 60px;
    }
    .content{
      // width: 35%;
      // margin: 0 10px;
      // padding: 80px 0;
      background: #F1F5FF;
      border-radius: 20px;
      width: 28%;
      text-align: center;
      color:black;
      padding: 0 0 60px 0;
      .bg{
        width: 100%;
      }
      .bg2{
        display: block;
        width: 45%;
        margin: -90px auto 0;
      }
      h1{
        font-size: 20px;
        // line-height: 50px;
        margin:0 0 15px 0;
      }
      p{
        color: #666666;
        font-size: 14px;
        line-height: 20px;
        font-weight: bold;
      }
    }
  }
  .inner3{
    position: relative;
    padding:80px calc((100% - 1200px) / 2);
    background:url('');
    background: #0071D6;
    justify-content: center;
    .bg{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 658px;
      height: 400px;
    }
    .title{
      p{
        color: #666666;
        font-size: 16px;
        margin: 0;
      }
      h3{
        font-size: 40px;
        color: white;
        text-align: center;
        position: relative;
        margin-bottom: 50px;
        // &::after{
        //   display: block;
        //   content:'';
        //   width: 80px;
        //   height: 5px;
        //   background: #00BB53;
        //   margin: 20px auto 0;
        // }
      }
    }
    .content{
      width: 30%;
      text-align: left;
      color: white;
      margin-bottom: 40px;
      margin-left: 70px;
      img{
        width: 45px;
        height: 45px;
        margin-right: 20px;
      }
      h1{
        font-size: 22px;
        line-height: 45px;
        // line-height: 50px;
        // margin:0  20px;
      }
      p{
        text-align: left;
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
  .inner4{
    position: relative;
    padding:90px calc((100% - 1100px) / 2) 60px;
      // background: linear-gradient(86deg, #013799 0%, #0071D6 100%);
    h3{
      font-size: 30px;
      color: black;
      margin-bottom: 60px;
      text-align: center;
    }
    .content{
      // width: 35%;
      // margin: 0 10px;
      // padding: 80px 0;
      width: 100%;
      text-align: left;
      color:white;
      margin-bottom: 60px;
      background: #F1F5FF;
      h1{
        font-size: 24px;
        margin-bottom: 40px;
        // margin:0 0 50px 0;
        position: relative;
      }
      p{
        text-align: left;
        font-size: 15px;
        font-weight: bold;
        width: 70%;
        margin-left: 28px;
        position: relative;
        margin-bottom: 20px;
        &::before{
          position: absolute;
          display: block;
          content:'';
          width: 10px;
          height: 10px;
          border-radius: 5px;
          background: #0071D6;
          margin:0 10px 5px 0;
          left: -28px;
          top: 7px;
        }
      }
      h2{
        color: #666666;
        text-align: left;
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 20px;
        font-weight: 100 !important;
        line-height: 23px;
      }
      img{
        width: 500px;
      }
    }
    .content2{
      width: 42%;
      text-align: left;
      color: black;
      margin-left: 50px;
      justify-content: center;
      p{
        color: #666666;
        font-weight: 100 !important;
        line-height: 23px;
      }
    }
  }
  .inner5{
    background: #063EA4;
    padding: 50px calc((100% - 1300px) / 2) 60px;
    h3{
      font-size: 30px;
      color: white;
      text-align: center;
      // margin-bottom: 20px;
      position: relative;
      margin-bottom: 60px;
      // &::after{
      //   display: block;
      //   content:'';
      //   width: 80px;
      //   height: 5px;
      //   background: #00BB53;
      //   margin: 20px auto 0;
      // }
    }
    p{
      padding: 10px 0px 0;
      font-size: 12px;
      color: #666666;
      text-align: left;
      line-height: 18px;
    }
    .carousel-container {
      position: relative;
      width: 100%;
      height: 300px;
    }
    .carousel {
      display: flex;
      overflow: hidden;
      height: 100%;
    }
    .carousel-wrapper {
      display: flex;
      width: 100%;
      justify-content:space-around;
      align-items: center;
    }
    .carousel-item {
      min-width: 340px !important;
      width: 30% !important;
      height: 260px;
      display: flex;
      align-items: center;
      background: #FFFFFF;
      color: #fff;
      box-shadow: 0px 3px 15px 1px rgba(93,93,93,0.16);
      border-radius: 5px;
      font-size: 18px;
      padding: 20px 15px 10px;
      margin: 0 20px;
      .stars{
        img{
          width: 14px;
          height: 14px;
        }
      }
      h1{
        color: black;
        font-size: 12px;
        line-height: 18px;
        text-align:  center;
        font-weight: 500;
      }
      img{
        width: 70px;
        height: 70px;
      }
      p{
        height: 110px;
      }
    }
    .carousel-button {
      // position: absolute;
      // padding: 20px;
      font-size: 20px;
      border: none;
      background-color: transparent;
      color: #555;
      outline: none;
      cursor: pointer;
      img{
        width: 70px;
      }
    }
    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.2s, transform 0.2s;
    }

    .fade-enter,
    .fade-leave-to {
      opacity: 0;
      transform: translateX(200px);
    }
  }
}
@media only screen and (max-width: 720px){
  .home_top{
    min-width: calc(100vw) !important;
    .inner{
      min-width: calc(100vw) !important;
      display: flex;
      flex-wrap: wrap;
    }
    .inner1{
      padding: 0px calc((100% - 1200px) / 2) 0;
      height: 450px;
      position: relative;
      background-size: 100% auto;
      .left_item{
        // margin: 0px 10px 10px;
        padding: 30px 0px 0 0 !important;
        flex: 1;
        h1{
          font-size: 14px;
          line-height: 35px;
          // line-height: 50px;
          color: white !important;
          padding-left: 10px;
        }
        h4{
          color: white;
          font-size: 12px;
          margin: 0 !important;
        }
        & h4:nth-of-type(1){
          margin-top: 60px;
        }
        .item_download {
          margin-top: 30px;
          padding-left: 0 !important;
          img{
            display: block;
            width: 150px;
            height: 50px;
            margin: 0 auto;
          }
        }
        .wbg{
          // background: linear-gradient(86deg, #013799 0%, #0071D6 100%);
        }
        .item_info{
          background: #063EA4;
          padding-left: 10px;
          margin: 30px 0;
          justify-content: center;
          .info_tips{
            p{
              font-size: 12px;
            }
            img{
              width: 15px;
            }
          }
        }
      }
      .right_item{
        width: calc(100vw);
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 100% !important;
          position: absolute;
          bottom: 0;
          margin-right: 0;
          height: 350px;
        }
      }
    }
    .inner2{
      padding: 0px calc((100% - 1200px) / 2) 50px;
      .inner2_bg{
        background: none;
      }
      div{
        width: 100%;
        padding: 0 20px;
      }
      h3{
        width: 100%;
        font-size: 25px;
        text-align: center;
        margin-bottom: 30px;
      }
      .inner2_item{
        flex-direction: column;
      }
      .content{
        width: 100%;
        // padding: 20px 0;
        margin-bottom: 50px;
        h1{
          margin: 10px 0 20px;
          font-size: 20px;
          text-align:  center !important;
        }
      }
      .text_r{
        text-align: left !important;
        p{
          text-align: left !important;
        }
      }
      .content2{
        text-align: left;
        p{
        text-align: left;
        }
      }
    }
    .inner3{
      min-width: calc(100vw - 40px) !important;
      width: calc(100vw - 40px) !important;
      margin: 0 auto !important;
      padding: 50px 0px;
      h1,h2,h3,p{
        text-align: center !important;
      }
      .flex{
        flex-direction: column;
      }
      .bg{
        width: 100%;
      }
      .content{
        width: 100%;
        margin: 0 0 20px 0 !important;
        img{
          margin: 0 auto;
          // width: calc(100vw - 40px) !important;
        }
        div{
          width: calc(100vw - 40px) !important;
          p{
            font-size: 12px;
          }
        }
      }
      .title{
        width: 100%;
        margin-bottom: 20px;
      }
      .inner5_item{
        display: flex;
        flex-direction: column;
      }
    }
    .inner4{
      min-width: calc(100vw - 40px) !important;
      width: calc(100vw) !important;
      margin: 0 auto !important;
      padding: 50px 0px 0;
      div{
        width: 100%;
      }
      p{
        padding: 10px 0 20px;
      }
      h3{
        width: 100%;
        font-size: 25px;
        text-align: center;
        margin-bottom: 20px;
      }
      .content{
        flex-direction: column;
        margin-bottom: 20px;
        h1{
          font-size: 20px;
          margin: 20px 0;
        }
        img{
          width: 100% !important;
          margin: 0;
        }
        p{
          width: 80% !important;
          margin: 0 auto;
          font-size: 12px;
        }
      }
      .content2{
        width: 100%;
        align-items: center;
        justify-content: center;
        text-align: center !important;
        padding: 0 !important;
        margin-left: 0;
        h2{
          margin: 0 20px;
          font-size: 12px;
          margin-bottom: 20px;
        }
      }
      .left_bg{
        width: 30px;
        height: 50px;
        top: 40px;
      }
      .inner5_item{
        margin:  0 auto;
        padding: 0;
        display: flex;
        flex-direction: column;
        .content{
          flex: 1;
          width: 100%;
          margin: 20px 0;
        }
      }
      .carousel-button{
        padding: 0 10px;
        img{
          width: 10px;
        }
      }
      .carousel-container{
        height: 600px;
      }
      .carousel-wrapper{
        padding: 0 20px;
        flex-wrap: wrap;
        .carousel-item{
          width: 45%;
          margin: 0 5px;
        }
      }
      .carousel-item{
        width: 150px;
        height: 280px;
        img{
          width: 60px;
        }
        h1{
          font-size: 13px;
          height: 40px;
          padding: 0;
          line-height: 16px;
        }
        p{
          font-size: 12px;
          height: 90px;
          padding: 0 5px;
          line-height: 18px;
        }
      }
    }
    .inner5{
      padding: 30px 20px 30px;
      position: relative;
      display: none;
      h3{
        text-align: center;
        margin-bottom: 30px;
        line-height: 30px;
      }
      .carousel-container{
        height: 320px;
      }
      .carousel-item{
        min-width: 150px !important;
        width: 45% !important;
        flex-direction: column;
        align-items: center !important;
        justify-content: center !important;
        h1{
          font-size: 12px;
          padding: 0 !important;
        }
        img{
          margin-bottom: 10px;
        }
        p{
          display: none;
        }
      }
      .carousel-button{
        position: absolute;
      }
    }
  }
}
</style>
